import { Button, Grid, Img } from '@chakra-ui/react'
import { CATEGORIES } from '../../../core/constants'

export const CategoriesGrid = ({ onNavigateToProducts }) => {
  console.log(onNavigateToProducts)
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      gap={4}
      p="4"
      mt="2"
    >
      {CATEGORIES.map((category) => (
        <CategoryItem
          key={category.id}
          name={category.name}
          iconSrc={category.iconSrc}
          categoryId={category.id}
          onNavigateToProducts={onNavigateToProducts}
        />
      ))}
    </Grid>
  )
}

const CategoryItem = ({ name, categoryId, onNavigateToProducts, iconSrc }) => {
  return (
    <Button
      bgColor="primary"
      color="textLight"
      leftIcon={<Img src={iconSrc} alt={name} mr="1" />}
      fontWeight="bold"
      fontSize="14px"
      width="100%"
      height="64px"
      // justifyContent="space-between"
      onClick={() => onNavigateToProducts(categoryId)}
      borderRadius={10}
      _focus={{ boxShadow: 'none' }}
      _hover={{ bgColor: 'blue' }}
    >
      {name}
    </Button>
  )
}
