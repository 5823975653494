import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider, QueryClient } from 'react-query'
// import { Amplify } from 'aws-amplify'

import { Router } from './pages'
import theme from './theme'
// import awsconfig from './aws-exports'
import { StoreProvider } from './core/contexts/StoreContext'
import { CheckoutProvider } from './core/contexts/CheckoutContext'

const queryClient = new QueryClient()

// Amplify.configure(awsconfig)

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <StoreProvider>
          <CheckoutProvider>
            <ChakraProvider theme={theme}>
              <Router/>
            </ChakraProvider>
          </CheckoutProvider>
        </StoreProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
