import { Box, Heading, HStack } from '@chakra-ui/react'
import React from "react";

export const OrderListHeader = () => {
  return (
    <Box width="100%" px="4">
      <Heading color="tgTextColor">
        Cart
      </Heading>
    </Box>
  );
};
