import { VStack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { AddToCartBtn } from './AddToCartBtn'
import { ProductOptions } from './ProductOptions'
import { NumberInput } from '../../../components'

export const ProductOptionalInfo = ({
                                      onAddToCart,
                                      setRadioChecked,
                                      radioChecked,
                                      setProductOption,
                                      product,
                                      productQuantity,
                                      setProductQuantity,
                                      productOptions
                                    }) => {
  console.log('product', product)
  console.log('productOptions', productOptions)
  // const isProductOptions = useMemo(() => product.attributes?.[0]?.options.length > 0, [product.attributes])
  const isProductOptions = useMemo(() => productOptions.length > 0, [productOptions?.length])
  const isButtonDisabled = useMemo(() => {
    if (product.attributes?.[0]?.options.length > 0 && productOptions?.length === 0) {
      return false
    }

    return product.isOutOfStock ||
      (isProductOptions && !radioChecked)
  }, [product, radioChecked, isProductOptions])

  return (
    <VStack
      spacing="32px"
      width="100%"
      marginTop="5"
      align="start"
    >
      {isProductOptions ? (
        <ProductOptions
          setRadioChecked={setRadioChecked}
          setProductOption={setProductOption}
          productOptions={productOptions}
        />
      ) : null}

      <NumberInput value={productQuantity} onChange={setProductQuantity}/>

      <AddToCartBtn
        onAddToCart={onAddToCart}
        radioChecked={radioChecked}
        isDisabled={isButtonDisabled}
      />
      {/*<ProductDetailsAccordion product={product} />*/
      }
    </VStack>
  )
}
