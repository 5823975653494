export const CATEGORIES = [
  {
    name: 'Disposables',
    id: 1730,
    slug: 'disposables',
    iconSrc: '/assets/icons/disposables.svg'
  },
  {
    name: 'Ejuices',
    id: 1732,
    slug: 'ejuices',
    iconSrc: '/assets/icons/ejuices.svg'
  },
  {
    name: 'Accessories',
    id: 1731,
    slug: 'accessories',
    iconSrc: '/assets/icons/accessories.svg'
  },
  {
    name: 'Devices',
    id: 1726,
    slug: 'devices',
    iconSrc: '/assets/icons/devices.svg'
  }
]

export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GA_TRACKING_ID || ''
