import { Grid, GridItem, Heading } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SkeletonList } from './components/SkeletonList'
import { useProductsByCategory } from '../../core/hooks/products/useProductsByCategory'
import { MainLayout, ProductCard } from '../../components'
import { CATEGORIES } from '../../core/constants'
import InfiniteScroll from 'react-infinite-scroll-component'

const skeletonListLength = 6
const PER_PAGE = 10

export const ProductsList = () => {
  const [loadedCardsCount, setLoadedCardsCount] = useState(0)
  const location = useLocation()
  const categoryId = location.state.categoryId
  const {
    allProducts,
    fetchNextPage,
    isLoading,
    pageProductsLength
  } = useProductsByCategory({
    categoryId,
    perPage: PER_PAGE
  })
  console.log('allProducts', allProducts)

  const navigate = useNavigate()
  const onOpenDetailedProduct = (productId) => {
    console.log('productId', productId)
    navigate('/product', { state: { productId: productId } })
  }

  const categoryName = CATEGORIES.find((category) => category.id === categoryId).name

  const hasMore = useMemo(() => {
    if (isLoading) return true

    return pageProductsLength === PER_PAGE
  }, [pageProductsLength, isLoading])
  console.log('hasMore', hasMore)

  return (
    <MainLayout>
      <Heading fontSize="16px" px="4" color="black" mt="1">
        {categoryName}
      </Heading>

      <InfiniteScroll
        dataLength={loadedCardsCount}
        next={fetchNextPage}
        hasMore={hasMore}
        loader={<SkeletonList skeletonListLength={skeletonListLength}/>}
        style={{ overflow: 'hidden' }}
        scrollableTarget="scrollable-box"
      >
        <Grid
          templateColumns="repeat(2,  minmax(0, 1fr))"
          gap="4"
          width="100%"
          pt="2"
          pb="8"
          px="4"
        >
          {allProducts?.map((product) => {
            return (
              <GridItem key={product.id}>
                <ProductCard
                  setLoadedCardsCount={setLoadedCardsCount}
                  {...product}
                  onOpenDetailedProduct={onOpenDetailedProduct}
                ></ProductCard>
              </GridItem>
            )
          })}
        </Grid>
      </InfiniteScroll>
    </MainLayout>
  )
}