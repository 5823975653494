import ReactGA from "react-ga4";
import { MainLayout } from "../../components";
import { Button, Flex, Heading, Image, Spinner, Text } from "@chakra-ui/react";
import { useOrderStatus } from "../../core/hooks/payment";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as ReactLink } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import StoreContext from "../../core/contexts/StoreContext";

export const OrderStatus = () => {
  const { setCart } = useContext(StoreContext);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const orderId = useMemo(
    () => searchParams.get("orderId")?.trim(),
    [location.search]
  );
  const orderStatus = useMemo(
    () => searchParams.get("order-status"),
    [location.search]
  )?.trim();

  const [isSuccess, setIsSuccess] = useState(orderStatus === "success");

  console.log("orderId:", orderId);
  const { data } = useOrderStatus(orderId, {
    enabled: !!orderId && !isSuccess,
    onSuccess: (data) => {
      if (!data?.amount) {
        return navigate("/collections");
      }

      if (data.actionCode === 0) {
        return setIsSuccess(true);
      }

      const { payload } = data;

      console.log("payload:", payload);
      setCart({
        type: "setCart",
        cart: {
          items: payload.orders,
          total: payload.total,
        },
      });

      const queryParams = new URLSearchParams({
        phone: payload.phone,
        name: payload.name,
        address: payload.address,
        paymentMethod: payload.paymentMethod,
        error: data.actionCodeDescription,
      });

      if (payload.email) {
        queryParams.set("email", payload.email);
      }

      const queryString = queryParams.toString();
      navigate(`/checkout?${queryParams}`);
    },
  });

  if (isSuccess) {
    return <SuccessView />;
  }

  return (
    <MainLayout
      isScreenHeight
      showNavbar={false}
      containerProps={{
        align: "center",
        justify: "center",
      }}
    >
      <Spinner size="xl" speed="0.8s" />
    </MainLayout>
  );
};

const SuccessView = () => {
  const { cart } = useContext(StoreContext);

  useEffect(() => {
    // Create a unique transaction_id based on product IDs and quantities
    const fullTransactionId = cart.items
      .reduce((acc, item) => {
        return acc + `${item.id}:${item.quantity}-`;
      }, "")
      .slice(0, -1); // Remove the trailing dash

    // Generate a short checksum
    const generateChecksum = (str) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32-bit integer
      }
      return Math.abs(hash).toString(36).slice(0, 8); // Convert to base36 and take first 8 characters
    };

    const transaction_id = generateChecksum(fullTransactionId);

    ReactGA.event("purchase", {
      transaction_id: transaction_id,
      currency: "AMD",
      value: cart.total,
      items: cart.items.map((item) => ({
        item_id: item.id,
        item_name: item.title,
        price: item.price,
        quantity: item.quantity,
        item_variant: item.productOption,
        ...(item.categories &&
          item.categories.reduce(
            (acc, category, index) => ({
              ...acc,
              [`item_category${index > 0 ? index + 1 : ""}`]: category.name,
            }),
            {}
          )),
      })),
    });
  }, [cart]);

  return (
    <MainLayout
      isScreenHeight
      containerProps={{
        align: "center",
        p: 4,
      }}
    >
      <Flex
        maxWidth="300px"
        width="full"
        direction="column"
        align="center"
        height="full"
        mt="200px"
      >
        <Image src="/assets/icons/thank-you.svg" width="50px" />

        <Heading fontSize="20px" align="center" mt="6">
          Thank you! Your order successfully been placed.
        </Heading>

        <Text fontSize="14px" align="center" mt="2">
          Our courier will get in touch with you soon!
        </Text>

        <Button mt="7" width="full" as={ReactLink} to="/collections">
          Explore
        </Button>
      </Flex>
    </MainLayout>
  );
};
