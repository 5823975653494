import { Button } from '@chakra-ui/react'

export const AddToCartBtn = ({ onAddToCart, isDisabled }) => {
  const handleAddToCart = () => {
    if (isDisabled) {
      return
    }

    onAddToCart()
  }

  return (
    <Button
      onClick={handleAddToCart}
      width="full"
      isDisabled={isDisabled}
    >
      Add to cart
    </Button>
  )
}
//  PRODUCTS_API_URL in tglambda: · https://vape.am
// ✔ Enter the missing environment variable value of PRODUCTS_API_KEY in tglambda: · ck_431bdc71caeac741ce92b8e7afd826ceed05802f
// ✔ Enter the missing environment variable value of PRODUCTS_API_SECRET in tglambda: · cs_e6d128a73eb3b356f1f238bbaa5547e2242e43eb
// ✔ Enter the missing environment variable value of PAYMENT_USERNAME in tglambda: · 14533954_api
// ✔ Enter the missing environment variable value of PAYMENT_PASSWORD in tglambda: · 43\\Tt2?Ih.£C
