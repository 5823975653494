export default function telegramUseCase(telegramService) {
  const webAppExpand = () => {
    return telegramService.webAppExpand();
  };

  const mainBtnClick = (mainBtnClickHandler) => {
    return telegramService.mainBtnClick(mainBtnClickHandler);
  };

  const setMainBtnText = (text) => {
    return telegramService.setMainBtnText(text);
  };

  const mainBtnDisable = () => {
    return telegramService.mainBtnDisable();
  }

  const mainBtnEnable = () => {
    return telegramService.mainBtnEnable();
  }

  const mainBtnShow = () => {
    return telegramService.mainBtnShow();
  };

  const mainBtnHide = () => {
    return telegramService.mainBtnHide();
  };

  const mainBtnShowProgress = () => {
    return telegramService.mainBtnShowProgress();
  };

  const mainBtnHideProgress = () => {
    return telegramService.mainBtnHideProgress();
  };

  const backBtnClick = (telegramBackBtnHandler) => {
    return telegramService.backBtnClick(telegramBackBtnHandler);
  };

  const backBtnHide = () => {
    return telegramService.backBtnHide();
  };

  const backBtnShow = () => {
    return telegramService.backBtnShow();
  };

  const createInvoiceLink = (cart) => {
    let params = {
      queryStringParameters: {
        prices: JSON.stringify(
          cart.items.map((item) => {
            return {
              label: ` ${item.title} ${item.quantity}x`,
              amount: item.price * item.quantity * 100,
            };
          })
        ),
        productTitle: "Product Title",
        productDescription: "Product Description",
        image: "https://cdn.shopify.com/static/share-image-common.jpg",
      },
    };
    return telegramService.createInvoiceLink(params);
  };

  const openInvoice = (params) => {
    telegramService.openInvoice(params, (status) => {
      status === "paid" ? closeWebApp() : mainBtnHideProgress();
    });
  };

  const closeWebApp = () => {
    return telegramService.closeWebApp();
  };

  return {
    createInvoiceLink,
    openInvoice,
    backBtnClick,
    backBtnHide,
    backBtnShow,
    webAppExpand,
    mainBtnClick,
    setMainBtnText,
    mainBtnShow,
    mainBtnHide,
    mainBtnShowProgress,
    mainBtnHideProgress,
    mainBtnDisable,
    mainBtnEnable,
    closeWebApp,
  };
}
