import { Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from "react";

export const ProductMainInfo = ({ product }) => {
  return (
    <VStack width="100%" pos="relative" spacing="16px" >
      <Image
        src={product.imageSrc}
        loading="eager"
        pointerEvents="visible"
        objectFit="cover"
        minH={0}
        minW={0}
        margin="0 auto"
        width="100%"
        height="100%"
        overflow="hidden"
        __css={{
          aspectRatio: "1/1",
        }}
      ></Image>

      <VStack
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
        spacing={0}
        fontWeight="bold"
      >
        <Text fontSize="16px" mt="3">
          {product.name}
        </Text>

        <Flex justify="space-between" width="full" mt="3">
          <Text align="start"  fontSize="16px" fontWeight="bold">
            {product.price} ֏
          </Text>

          {product.isOutOfStock ? (
          <Text align="end" fontSize="16px" color="red" ml="4">
            Out of stock
          </Text>
        ) : null }
        </Flex>
      </VStack>
    </VStack>
  );
};
