import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { DELIVERY_PRICE } from '../../../core/contexts/StoreContext'

export const TotalAmount = ({ totalAmount = 0 }) => {
  return (
    <Box
      mt="10"
      px="4"
      pb="6"
      fontSize="16px"
      fontWeight="bold"
    >
      <Flex justify="space-between" >
        <Text>Delivery</Text>

        <Text>{DELIVERY_PRICE} ֏</Text>
      </Flex>

      <Divider my="3" borderColor="black"/>

      <Flex justify="space-between">
        <Text>Total</Text>

        <Text>{totalAmount} ֏</Text>
      </Flex>
    </Box>
  )
}
