import { useQuery } from 'react-query'
import { paymentUseCase } from '../../factories'

export const useOrderStatus = (orderId, options = {}) => {
  return useQuery(
    ['order-status', orderId],
    () => paymentUseCase.orderStatus(orderId),
    options
  )
}
