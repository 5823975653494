import { useMemo } from 'react'
import { useProduct } from '../product/useProduct'

export const useBestSellersProducts = () => {
  const [product1, isSuccess1] = useProduct('659837')
  const [product2, isSuccess2] = useProduct('659817')

  const bestSellers = useMemo(() => {
    if (isSuccess1 && isSuccess2) {
      return [product1, product2].filter(product => product?.id)
    }

    return []
  }, [product1, product2, isSuccess1, isSuccess2])

  return [bestSellers, isSuccess1 && isSuccess2]
}