import { Box, Flex, Grid } from '@chakra-ui/react'
import { Navbar } from './Navbar'

export const MainLayout = ({ children, showNavbar = true, isScreenHeight = false, containerProps={}, ...props }) => {
  return (
    <Flex
      width="100vw"
      bgColor="white"
      direction="column"
      minHeight={isScreenHeight ? '100vh' : 'auto'}
      {...props}
    >
      {showNavbar ?
        <Navbar/>
        : null}

      <Flex
        flex="1" direction="column" height="full" width="full"
        mt={showNavbar ? '64px' : '0'}
        {...containerProps}
      >
        {children}
      </Flex>
    </Flex>
  )
}
