import { useNavigate } from 'react-router-dom'
import { CategoriesGrid } from './components/CategoriesGrid'
import { LoadingView } from './components/LoadingView'
import { Banner } from './components/Banner'
import { BestSellers } from './components/BestSellers'
import { useBestSellersProducts } from '../../core/hooks/products/useBestSellersProducts'
import { MainLayout } from '../../components'

export const CategoriesList = () => {
  const navigate = useNavigate()
  const onNavigateToProducts = (categoryId) => {
    navigate('/products', { state: { categoryId } })
  }

  const onOpenDetailedProduct = (productId) => {
    navigate('/product', { state: { productId } })
  }

  const [products, isSuccess] = useBestSellersProducts()
  console.log('CategoriesList@products', products)

  if (!isSuccess) {
    return <LoadingView/>
  }

  return (
    <MainLayout isScreenHeight>
      <Banner/>
      <BestSellers products={products} onOpenDetailedProduct={onOpenDetailedProduct}/>
      <CategoriesGrid onNavigateToProducts={onNavigateToProducts}/>
    </MainLayout>
  )
}
