import { Skeleton, SkeletonText, VStack } from "@chakra-ui/react";
import React from "react";

export const SkeletonProduct = () => {
  return (
    <VStack spacing={10} h="100vh">
      <VStack>
        <Skeleton h="96vw" w="96vw" />
        <VStack left={0} width="100%" alignItems="flex-start">
          <Skeleton h="16px" w="20vw" />
          <Skeleton h="16px" w="30vw" />
        </VStack>
      </VStack>
      <SkeletonText h="16px" noOfLines={4} spacing="4" width="100%" />
    </VStack>
  );
};
