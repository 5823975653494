import { Box, useRadio } from '@chakra-ui/react'

export const SizesRadioCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label" width="100%">
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        alignItems="center"
        justifyContent="center"
        rounded="12px"
        cursor="pointer"
        color="black"
        border="1px solid"
        borderColor="borderLight"
        fontSize="14px"
        bgColor="white"
        _checked={{
          bgColor: "primary",
          color: "white",
          borderColor: "primary",
        }}
        width="auto"
        height="32px"
        px="3"
      >
        {props.children}
      </Box>
    </Box>
  )
}