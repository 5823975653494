import axios from 'axios';

export class ProductService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_PRODUCTS_API_URL + '/store'
    });
  }

  async getProducts(filter = {}) {
    const response = await this.api.get('products', {
      params: filter
    });
    return response.data;
  }

  async getProductVariations(productId) {
    const response = await this.api.get(`products/${productId}/variations`, {
      params: {
        per_page: 50
      }
    });
    return response.data;
  }

  async getProduct(productId) {
    const response = await this.api.get(`product/?productId=${productId}`);
    return response.data;
  }
}