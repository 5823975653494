import { Box, Flex, Text } from '@chakra-ui/react'

export const Banner = () => {
  return (
    <Box px="4">
      <Flex
        width="full"
        height="120px"
        bgImage="/assets/banner.jpg"
        bgSize="cover"
        bgRepeat="no-repeat"
        rounded="xl"
        align="center"
        pl="7"
      >

        <Text
          color="white" fontSize="24px"
          fontWeight="bold"
          width="156px"
          lineHeight="1"
        >
          #1 Vape Store
          in Armenia
        </Text>
      </Flex>
    </Box>
  )
}

