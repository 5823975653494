export class PaymentUseCase {
  constructor(paymentService) {
    this._paymentService = paymentService
  }

  async payment(data) {
    try {
      const response = await this._paymentService.payment(data)
      console.log('paymentResponse : ', response)

      return response
    } catch (error) {
      console.error('Error making payment:', error)
      return []
    }
  }


  async orderStatus(orderId) {
    try {
      console.log('PaymentUseCase@orderStatus@orderId', orderId)
      const response = await this._paymentService.orderStatus(orderId)
      console.log('orderStatusResponse : ', response)

      return response
    } catch (error) {
      console.error('Error getting order status:', error)
      return null
    }
  }

  async sendOrderInvoice(data) {
    try {
      const response = await this._paymentService.sendOrderInvoice(data)
      console.log('sendOrderInvoiceResponse : ', response)

      return response
    } catch (error) {
      console.error('Error sending order invoice:', error)
      return null
    }
  }
}