import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { ProductCard } from 'components'

export const BestSellers = ({ products, onOpenDetailedProduct }) => {
  console.log('BestSellers#products : ', products)
  return (
    <Box p="4">
      <Flex justify="space-between">
        <Text fontWeight="bold" fontSize="16px">
          Best Sellers
        </Text>
      </Flex>

      <Grid
        templateColumns="repeat(2,  minmax(0, 1fr))"
        gap="4"
        mt="4"
      >
        {
          products.map(product => (
            <GridItem key={product.id}>
              <ProductCard {...product} onOpenDetailedProduct={onOpenDetailedProduct}/>
            </GridItem>
          ))
        }
      </Grid>
    </Box>
  )
}



