import { Flex, useRadioGroup } from '@chakra-ui/react'
import { SizesRadioCard } from './SizesRadioCard'

export const ProductOptions = ({ setRadioChecked, setProductOption, productOptions=[] }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'product-options',
    onChange: (value) => {
      setRadioChecked(true)
      setProductOption(value)
    }
  })
  const group = getRootProps()

  return (
    <Flex
      {...group}
      gap="2"
      width="100%"
      flexWrap="wrap"
    >
      {productOptions.map((option) => {
        const radio = getRadioProps({ value: option.id })
        return (
          <Flex
            textAlign="center"
            display="flex"
            justifyContent="center"
            key={option.id}
          >
            <SizesRadioCard {...radio}>{option.name}</SizesRadioCard>
          </Flex>
        )
      })}
    </Flex>
  )
}