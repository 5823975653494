const styles = {
  global: {
    html: {
      backgroundColor: "white",
      WebkitTapHighlightColor: "transparent",
      scrollbarWidth: "none",
      margin: 0,
      padding: 0,
      overflowY: "scroll",
      overflowX: "hidden",
    },
    body: {
      overflowX: "hidden",
      overflowY: "scroll",
      backgroundColor: "white",
      userSelect: "none",
      scrollbarWidth: "none",
      margin: 0,
      padding: 0,
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    },
    "::-webkit-scrollbar": {
      display: "none",
    },
    ul: {
      listStyle: "inside",
    },
  },
};

export default styles;
