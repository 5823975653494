import React from 'react'
import { Button, Input, HStack, IconButton } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

export const NumberInput = ({ value, onChange }) => {
  const handleDecrement = () => {
    if (value > 1) {
      onChange(value - 1)
    }
  }

  const handleIncrement = () => {
    onChange(value + 1)
  }

  return (
    <HStack spacing="1" border="1px solid" borderColor="black" rounded="6px" height="32px">
      <IconButton onClick={handleDecrement} size="sm" variant="unstyled" icon={<MinusIcon/>} mt="-0.5"/>

      <Input
        value={value}
        readOnly
        textAlign="center"
        width="40px"
        size="sm"
        variant="unstyled"
      />

      <IconButton onClick={handleIncrement} size="sm" variant="unstyled" icon={<AddIcon/>} mt="-0.5"/>
    </HStack>
  )
}
