import { Box, Flex } from '@chakra-ui/react'
import { Logo } from './Logo'
import { Link as ReactLink } from 'react-router-dom'

export const Navbar = () => {
  return (
    <Flex align="center" height="64px" width="full" px="4" position="fixed" bgColor="white" zIndex="100">
      <Box as={ReactLink} to="/collections">
        <Logo size="md"/>
      </Box>
    </Flex>
  )
}

