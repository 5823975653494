import { Flex, IconButton, Image, Text } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import React from 'react'
import { NumberInput } from '../../../components'

export const OrderCard = ({
                            product,
                            onQuantityChange,
                            onDeleteOrder,
                            onOpenDetailedProduct
                          }) => {
  return (
    <Flex width="full" height="84px">
      <Image
        src={product.image}
        borderRadius="8px"
        objectFit="cover"
        minH={0}
        minW={0}
        width="full"
        __css={{
          aspectRatio: '1/1'
        }}
        maxW="84px"
        onClick={() => onOpenDetailedProduct(product.id)}
      ></Image>

      <Flex width="full" ml="4" height="full" direction="column" pb="2">
        <Flex justify="space-between">
          <Text
            size="14px" fontWeight="600"
            onClick={() => onOpenDetailedProduct(product.id)}
          >
            {product.title}
          </Text>

          <DeleteOrderBtn onDeleteOrder={() => onDeleteOrder(product)}/>
        </Flex>

        <Flex justify="space-between" mt="auto" align="center">
          <Text fontWeight="bold">{product.price * product.quantity} ֏</Text>

          <NumberInput value={product.quantity} onChange={onQuantityChange}/>
        </Flex>
      </Flex>
    </Flex>
  )
}

const DeleteOrderBtn = ({ onDeleteOrder }) => {
  return (
    <IconButton
      variant="unstyled"
      onClick={onDeleteOrder}
      alignSelf="flex-start"
      color="black"
      icon={<CloseIcon/>}
      borderRadius="0"
      border="none"
      size="sm"
      mt="-2"
      mr="-2"
    />
  )
}

