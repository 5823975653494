import ReactGA from 'react-ga4';
import { CategoriesList } from './CategoriesList'
import { ProductsList } from './ProductsList'
import { DetailedProduct } from './DetailedProduct'
import { OrdersList } from './OrdersList'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { Checkout } from './Checkout'
import { useInitTelegram } from '../core/hooks/telegram/useInitTelegram'
import { OrderStatus } from './OrderStatus'
import { useScrollTopOnPageChange } from '../core/hooks/useScrollTopOnPageChange'
import { useEffect } from 'react';
import { GOOGLE_ANALYTICS_ID } from 'core/constants';

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

export const Router = () => {
  useInitTelegram()
  useScrollTopOnPageChange()

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title
    });
  }, [location]);

  return (
    <Routes>
      <Route exact path="/collections" element={<CategoriesList/>}></Route>
      <Route exact path="/products" element={<ProductsList/>}></Route>
      <Route exact path="/product" element={<DetailedProduct/>}></Route>
      <Route exact path="/orders" element={<OrdersList/>}></Route>
      <Route exact path="/checkout" element={<Checkout/>}></Route>
      <Route exact path="/order-status" element={<OrderStatus/>}></Route>
      <Route path="*" element={<Navigate to="/collections" replace/>}></Route>
    </Routes>
  )
}
