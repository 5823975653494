import { extendTheme } from '@chakra-ui/react'
import styles from './styles'

const overrides = {
  styles,
  colors: {
    tgBackgroundColor: 'var(--tg-theme-bg-color)',
    tgTextColor: 'var(--tg-theme-text-color)',
    tgButtonColor: 'var(--tg-theme-button-color)',
    tgButtonTextColor: 'var(--tg-theme-button-text-color)',
    tgHintColor: 'var(--tg-theme-hint-color)',
    tgLinkColor: 'var(--tg-theme-link-color)',
    blue: '#3E88F7',
    red: '#D80000',
    primary: '#007AFF',
    primaryLight: '#f2f8ff',
    borderLight: '#F2F2F2',
    textLight: '#ffffff'
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'bold'
      },
      defaultProps: {
        size: '16px'
      }
    },
    Text: {
      baseStyle: {
        // fontSize: ["10px", "12px", "15px", "20px"]
        fontSize: '14px',
        color: 'black'
      }
    },
    Button: {
      baseStyle: {
        fontWeight: 'normal'
      },
      variants: {
        primary: {
          bgColor: 'primary',
          color: 'textLight',
          rounded: '8px',
          fontWeight: 'semibold',
          _hover: {
            bgColor: 'primary',
            opacity: '0.8',
            _disabled: {
              bgColor: 'primary',
              opacity: '0.4'
            }
          },
          _disabled: {
            bgColor: 'primary',
            opacity: '0.4',
          }
        }
      },
      sizes: {
        lg: {
          height: '48px',
          fontSize: '16px'
        }
      },
      defaultProps: {
        size: 'lg',
        variant: 'primary'
      }
    }
  },
  fonts: {},
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px'
  }
}

export default extendTheme(overrides)
