export class ProductUseCase {
  constructor(productService) {
    this._productService = productService
  }

  async getProducts(filter) {
    try {
      const products = await this._productService.getProducts(filter)
      console.log('products : ', products)

      return this.normalizeProducts(products)
    } catch (error) {
      console.error('Error fetching products:', error)
      return []
    }
  }

  async getProductVariantions(productId) {
    try {
      const variations = await this._productService.getProductVariations(productId)

      return variations.map(variation => ({
        id: variation.id+'',
        name: variation.name,
        price: parseFloat(variation.price),
        imageSrc: variation.image.src,
        isOutOfStock: variation.stock_status === 'outofstock'
      }))
    } catch (error) {
      console.error('Error fetching product variations:', error)
      return []
    }
  }

  async getProductsByCategory({ categoryId, page = 1, perPage = 30 }) {
    try {
      const filter = {
        category: categoryId,
        page,
        per_page: perPage
      }
      const products = await this._productService.getProducts(filter)
      console.log('products by category : ', products)

      return this.normalizeProducts(products)
    } catch (error) {
      console.error('Error fetching products by category:', error)
      return []
    }
  }

  async getProduct(productId) {
    try {
      const product = await this._productService.getProduct(productId)
      console.log('product : ', product)

      return this.normalizeProducts([product])[0]
    } catch (error) {
      console.error('Error fetching product:', error)
      return null
    }
  }

  normalizeProducts(products) {
    return products
      .map(product => ({
        id: product.id+'',
        name: product.name,
        price: parseFloat(product.price),
        imageSrc: product.images[0].src,
        categories: product.categories,
        isOutOfStock: product.stock_status === 'outofstock',
        attributes: product.attributes.map(attribute => ({
          id: attribute.id,
          name: attribute.name,
          options: attribute.options
        }))
      }))
  }
}