import React from 'react'
import { Grid, GridItem, Skeleton } from '@chakra-ui/react'

export const SkeletonList = ({ skeletonListLength }) => {
  let skeletonArray = Array.apply(null, Array(skeletonListLength)).map(
    () => {
    }
  )
  return (
    <Grid
      templateColumns="repeat(2,  minmax(0, 1fr))"
      gap="4"
      width="full"
      pb="8"
      px="4"
      mt="-4"
    >
      {
        skeletonArray.map((num, index) => {
          return (
            <GridItem key={`product-${index}`}>
              <Skeleton
                isLoaded={false}
                height="248px"
                borderRadius={5}
              />
            </GridItem>
          )
        })
      }
    </Grid>
  )
}
