import { useMutation } from 'react-query'
import { paymentUseCase } from '../../factories'

export const useSendOrderInvoice = (options = {}) => {
  return useMutation(
    ['send-order-invoice'],
    data => paymentUseCase.sendOrderInvoice(data),
    options
  )
}

