import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { usePayment } from '../hooks/payment'
import { useSendOrderInvoice } from '../hooks/payment/useSendOrderInvoice'
import StoreContext from './StoreContext'
import ReactGA from 'react-ga4';
import { telegramUseCases } from '../factories'

export const CheckoutContext = createContext()

export const CheckoutProvider = ({ children }) => {
  const { cart, setCart } = useContext(StoreContext)
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const { mutateAsync: paymentAsync } = usePayment()
  const { mutateAsync: sendOrderInvoiceAsync } = useSendOrderInvoice()

  const [paymentError, setPaymentError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: {
      address: '',
      name: '',
      phone: '',
      email: '',
      orders: [],
      total: 0,
      paymentMethod: 'card'
    }
  })

  useEffect(() => {
    if (!getValues('address')) {
      setValue('address', searchParams.get('address') || '')
    }
    if (!getValues('name')) {
      setValue('name', searchParams.get('name') || '')
    }
    if (!getValues('phone')) {
      setValue('phone', searchParams.get('phone') || '')
    }
    if (!getValues('email')) {
      const email = searchParams.get('email')
      setValue('email', email ? email?.toLowerCase().trim() : '')
    }
    if (!getValues('paymentMethod')) {
      setValue('paymentMethod', searchParams.get('paymentMethod') || 'card')
    }
    if (searchParams.get('error')) {
      setPaymentError(searchParams.get('error'))
    }
  }, [searchParams, getValues, setValue])

  useEffect(() => {
    setValue('orders', cart.items)
    setValue('total', cart.total)
  }, [cart, setValue])

  const setActivePaymentMethod = (method) => {
    setValue('paymentMethod', method)
  }

  const proceedCheckout = async () => {
    console.log('proceedCheckout')
    const data = getValues()
    console.log('data:', data)

    ReactGA.event('begin_checkout', {
      currency: "AMD",
      value: data.total,
      items: data.orders.map(order => ({
        item_id: order.id,
        item_name: order.name,
        price: order.price,
        quantity: order.quantity,
        item_variant: order.option,
        ...(order.categories && order.categories.reduce((acc, category, index) => ({...acc, [`item_category${index > 0 ? index + 1 : ''}`]: category.name}), {}))
      })),
    });

    const orderData = {
      email: data.email.toLowerCase().trim(),
      phone: data.phone.trim(),
      address: data.address.trim(),
      name: data.name.trim(),
      paymentMethod: data.paymentMethod,
      orders: data.orders,
      total: data.total,
      userId: window.Telegram.WebApp.initDataUnsafe?.user?.id || ''
    }
    console.log('orderData:', orderData)

    let isInvalid = false

    if (!data.phone) {
      setError('phone', { type: 'manual', message: 'Invalid phone number' })
      isInvalid = true
    }
    if (!data.address) {
      setError('address', { type: 'manual', message: 'Address is required' })
      isInvalid = true
    }
    if (!data.name) {
      setError('name', { type: 'manual', message: 'Name is required' })
      isInvalid = true
    }
    console.log('orderData:', orderData)

    if (isInvalid) {
      return
    }

    clearErrors()
    setIsLoading(true)

    try {
      console.log('activePaymentMethod:', data.paymentMethod)
      if (data.paymentMethod === 'card') {
        const res = await paymentAsync(orderData)
        if (res.formUrl) {
          telegramUseCases.mainBtnHide()
          window.location.href = res.formUrl
        }
      } else {
        sendOrderInvoiceAsync(orderData)
        setCart({ type: 'resetCart' })
        telegramUseCases.mainBtnHide()
        navigate('/order-status?order-status=success')
      }

    } catch (error) {
      console.log('handleCheckout@payment Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        register,
        errors,
        setError,
        activePaymentMethod: watch('paymentMethod'),
        setActivePaymentMethod,
        proceedCheckout,
        paymentError,
        isLoading
      }}>
      {children}
    </CheckoutContext.Provider>
  )
}