import { Image, Text, Box } from '@chakra-ui/react'
import React from 'react'

export const ProductCard = ({
  id,
  name,
  price,
  imageSrc,
  setLoadedCardsCount,
  onOpenDetailedProduct,
}) => {
  return (
    <Box
      onClick={() => onOpenDetailedProduct(id)}
      cursor="pointer"
      alignItems="flex-start"
      width="full"
      height="248px"
      p="2"
    >
      <Image
        src={imageSrc}
        loading="eager"
        pointerEvents="visible"
        objectFit="contain"
        minH={0}
        minW={0}
        width="full"
        margin="0 auto"
        height="180px"
        overflow="hidden"
        __css={{
          aspectRatio: "1/1",
        }}
        onLoad={() => {
          console.log('image loaded')
          setLoadedCardsCount && setLoadedCardsCount((prev) => prev + 1);
        }}
      />

      <Text align="start" mt="3" noOfLines="1">
        {name}
      </Text>

      <Text align="start" fontWeight="bold" mt="3">
        {price} ֏
      </Text>
    </Box>
  );
};


