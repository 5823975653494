import { useMutation } from 'react-query'
import { paymentUseCase } from '../../factories'

export const usePayment = (options = {}) => {
  return useMutation(
    ['payment'],
    data => paymentUseCase.payment(data),
    options
  )
}

