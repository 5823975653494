import { Image } from '@chakra-ui/react'

export const Logo = ({ size = 'lg' }) => {
  return (
    <Image
      src="/assets/logo.png"
      height={SIZE_MAP[size]}
    />
  )
}

const SIZE_MAP = {
  lg: '64px',
  md: '32px',
}
