import { Box, useToast, VStack } from '@chakra-ui/react'
import { useContext, useMemo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4';
import { ProductMainInfo } from './components/ProductMainInfo'
import { ProductOptionalInfo } from './components/ProductOptionalInfo'
import StoreContext from '../../core/contexts/StoreContext'
import { SkeletonProduct } from '../../components/skeletons/SkeletonProduct'
import { useProduct } from '../../core/hooks/product/useProduct'
import { MainLayout } from '../../components'
import { useProductVariations } from '../../core/hooks/products/useProductVariations'

export const DetailedProduct = () => {
  const toast = useToast()
  const { setCart } = useContext(StoreContext)
  const [radioChecked, setRadioChecked] = useState(false)
  const location = useLocation()
  const productId = location.state.productId
  console.log('productId', productId)
  const [product, isSuccess] = useProduct(productId)
  console.log('product', product)
  const {
    data: productOptions,
    isLoading: isLoadingProductOptions
  } = useProductVariations(product.attributes?.[0]?.options.length > 0 ? productId : null)
  const [productOption, setProductOption] = useState()
  const [productQuantity, setProductQuantity] = useState(1)

  const onAddToCart = () => {
    setCart({
      type: 'addToCart',
      product: product,
      productOption: productOption || {},
      productId: productId,
      quantity: productQuantity,
    })

    setProductQuantity(1)

    ReactGA.event('add_to_cart', {
      currency: "AMD",
      value: product.price,
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          price: product.price,
          ...(product.categories && product.categories.reduce((acc, category, index) => ({...acc, [`item_category${index > 0 ? index + 1 : ''}`]: category.name}), {})),
          item_variant: productOption?.name,
          quantity: productQuantity
        }
      ]
    });

    toast({
      title: 'Successfully added to cart',
      containerStyle: {
        'rounded': '6px',
        '>div': {
          background: '#31D158'
        }
      },
      status: 'success',
      duration: 3000,
      isClosable: true
    })
  }

  const onProductOptionChange = (optionId) => {
    setProductOption(productOptions.find(productOption => productOption.id === optionId))
    setProductQuantity(1)
  }

  const activeProduct = useMemo(() => {
    if (productOptions.length > 0 && productOption?.id) {
      return productOptions.find(option => option.id === productOption?.id)
    }

    return product
  }, [product?.id, productOption?.id])

  useEffect(() => {
    if (product && product.id && product.name) {
      console.log('product', product)
      ReactGA.event('view_item', {
        currency: "AMD",
        value: product.price,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: product.price,
            item_variant: productOption?.name,
            quantity: 1
          }
        ]
      });
    }
  }, [product]);

  return (
    <MainLayout>
      <Box my={4} px={4}>
        {activeProduct?.id && !isLoadingProductOptions ? (
          <VStack>
            <ProductMainInfo product={activeProduct}/>

            <ProductOptionalInfo
              product={activeProduct}
              productOptions={productOptions}
              radioChecked={radioChecked}
              setRadioChecked={setRadioChecked}
              onAddToCart={onAddToCart}
              setProductOption={onProductOptionChange}
              productQuantity={productQuantity}
              setProductQuantity={setProductQuantity}
            />
          </VStack>
        ) : (
          <SkeletonProduct/>
        )}
      </Box>
    </MainLayout>
  )
}
