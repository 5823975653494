import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

export const useProduct = (productId) => {
  const { data = [], isSuccess } = useQuery(
    ['product', productId],
    () =>
      productUseCase.getProduct(productId),
    {
      refetchOnWindowFocus: false
    }
  )
  return [data, isSuccess]
}

