import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

export const useProductVariations = (productId) => {
  const { data = [], isSuccess, isLoading } = useQuery(
    ['product-variations', productId],
    () => productUseCase.getProductVariantions(productId),
    {
      refetchOnWindowFocus: false,
      enabled: !!productId
    }
  )

  return {
    data,
    isSuccess,
    isLoading
  }
}