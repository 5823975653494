import {  Grid } from '@chakra-ui/react'
import { Logo } from 'components'

export const LoadingView = () => {
  return (
    <Grid
      height="100vh"
      placeItems="center"
      p="4"
      bgColor="white"
    >
     <Logo />
    </Grid>
  )
}

