import { MainLayout } from '../../components'
import { Box, Flex, FormControl, FormLabel, HStack, Img, Input, Text, useToast, VStack } from '@chakra-ui/react'
import { forwardRef, useContext, useEffect } from 'react'
import { CheckoutContext } from '../../core/contexts/CheckoutContext'

export const Checkout = () => {
  const {
    register,
    errors,
    activePaymentMethod,
    setActivePaymentMethod,
    paymentError
  } = useContext(CheckoutContext)

  const toast = useToast()

  useEffect(() => {
    if (paymentError) {
      toast({
        title: paymentError,
        containerStyle: {
          'backgroundColor': 'red',
          'rounded': '6px'
        },
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [paymentError])

  return (
    <MainLayout
      containerProps={{ p: 4 }}
      isScreenHeight
    >
      <Box as="form" pb="6">
        <SectionLayout title="Delivery information">
          <VStack width="full" spacing="4">
            <InputField
              label="Address*"
              name="address"
              placeholder="your address"
              {...register('address', { required: true })}
              isInvalid={errors.address}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  document.querySelector('input[name="name"]').focus()
                }
              }}
            />
            <InputField
              label="Name*"
              name="name"
              placeholder="your Name"
              {...register('name', { required: true })}
              isInvalid={errors.name}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  document.querySelector('input[name="phone"]').focus()
                }
              }}
            />
            <InputField
              label="Phone number*"
              name="phone"
              placeholder="+374 ** ******"
              {...register('phone', {
                required: true,
                pattern: /^\+?\d*$/,
                onChange: (e) => {
                  e.target.value = e.target.value.replace(/[^0-9+]/g, '')
                }
              })}
              isInvalid={errors.phone}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  document.querySelector('input[name="email"]').focus()
                }
              }}
            />
            <InputField
              label="Email"
              name="email"
              placeholder="your email address"
              type="email"
              {...register('email', { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
              isInvalid={errors.email}
            />
          </VStack>
        </SectionLayout>

        <SectionLayout title="Choose a payment method" mt="5">
          <VStack width="full" spacing="3">
            <PaymentMethod isActive={activePaymentMethod === 'card'} onClick={() => setActivePaymentMethod('card')}>
              <Img src="/assets/icons/mastercard.svg"/>
              <Img src="/assets/icons/visa.svg"/>
            </PaymentMethod>

            <PaymentMethod isActive={activePaymentMethod === 'cash'} onClick={() => setActivePaymentMethod('cash')}>
              <Text fontWeight="bold" mr="6">Cash</Text>
            </PaymentMethod>
          </VStack>
        </SectionLayout>
      </Box>
    </MainLayout>
  )
}

const SectionLayout = ({ children, title, ...props }) => {
  return (
    <Box bgColor="borderLight" p="5" rounded="12px" {...props}>
      <Text fontSize="20px" fontWeight="medium" mb="14px">{title}</Text>

      {children}
    </Box>
  )
}

const InputField = forwardRef((
  {
    label,
    type = 'text',
    placeholder = '',
    isInvalid = false,
    ...props
  }, ref
) => {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel fontSize="12px" mb="1" px="2">{label}</FormLabel>
      <Input
        ref={ref}
        type={type}
        placeholder={placeholder}
        size="lg"
        bgColor="white"
        border="none"
        rounded="12px"
        _focus={{ border: 'none' }}
        _focusVisible={{ border: 'none' }}
        _active={{ border: 'none' }}
        _invalid={{ border: '1px solid', borderColor: 'red' }}
        {...props}
      />
    </FormControl>
  )
})

const PaymentMethod = ({ children, isActive, ...props }) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      bgColor="white"
      width="full"
      height="55px"
      pl="4"
      pr="8"
      rounded="12px"
      outline={isActive ? '2px solid #007AFF' : 'none'}
      {...props}
    >
      <Text>Checkout with </Text>
      <HStack>
        {children}
      </HStack>
    </Flex>
  )
}