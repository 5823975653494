import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

export const useProductsByCategory = ({ categoryId, perPage = 6 }) => {
  const [page, setPage] = useState(1)
  const [allProducts, setAllProducts] = useState([])
  const [pageProductsLength, setPageProductsLength] = useState(0)

  const { data = [], isSuccess, isLoading } = useQuery(
    ['products', categoryId, page],
    () => productUseCase.getProductsByCategory({ categoryId, page, perPage }),
    {
      refetchOnWindowFocus: false,
      enabled: !!categoryId
    }
  )

  useEffect(() => {
    if (isSuccess) {
      setAllProducts((prevProducts) => [...prevProducts, ...data])
      setPageProductsLength(data.length)
    }
  }, [data, isSuccess])

  const fetchNextPage = () => {
    setPage((prevPage) => prevPage + 1)
  }

  return { allProducts, isSuccess, page, fetchNextPage, isLoading, pageProductsLength }
}