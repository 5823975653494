import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import StoreContext from "../../core/contexts/StoreContext";
import { OrderListHeader } from "./components/OrderListHeader";
import { Orders } from "./components/Orders";
import { MainLayout } from '../../components'
import { TotalAmount } from './components/TotalAmount'

export const OrdersList = () => {
  const { cart, setCart } = useContext(StoreContext);

  const navigate = useNavigate();
  const onOpenDetailedProduct = (productId) => {
    navigate("/product", { state: { productId: productId } });
  };

  useEffect(() => {
    cart.items.length === 0 && navigate('/collections')

    if (cart && cart.items && cart.items.length > 0) {
      ReactGA.event('view_cart', {
        currency: "AMD",
        value: cart.total,
        items: cart.items.map(item => ({
          item_id: item.id,
          item_name: item.title,
          price: item.price,
          item_variant: item.productOption,
          quantity: item.quantity,
          ...(item.categories && item.categories.reduce((acc, category, index) => ({...acc, [`item_category${index > 0 ? index + 1 : ''}`]: category.name}), {}))
        }))
      });
    }
  }, [cart]);

  return (
    <MainLayout isScreenHeight>
      <OrderListHeader />
      <Orders
        cart={cart}
        setCart={setCart}
        onOpenDetailedProduct={onOpenDetailedProduct}
      />

      <TotalAmount totalAmount={cart.total}/>
    </MainLayout>
  );
};
