import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import StoreContext from '../../contexts/StoreContext'
import { telegramUseCases } from '../../factories'
import { CheckoutContext } from '../../contexts/CheckoutContext'

export const useInitTelegram = () => {
  const { cart } = useContext(StoreContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { proceedCheckout, isLoading } = useContext(CheckoutContext)

  const telegramMainBtnHandler = async () => {
    if (window.location.pathname === '/orders') {
      navigate('/checkout')
    } else if (window.location.pathname === '/checkout') {
      try {
        await proceedCheckout()
      } catch (error) {
        console.log('handleCheckout@payment Error:', error)
      }
    } else {
      navigate('/orders')
    }
  }

  const telegramBackBtnHandler = () => {
    if (document.referrer && new URL(document.referrer).origin !== window.location.origin) {
      window.location.href = document.referrer
    } else {
      window.history.back()
    }
  }

  useEffect(() => {
    telegramUseCases.webAppExpand()
    telegramUseCases.mainBtnClick(telegramMainBtnHandler)
    telegramUseCases.backBtnClick(telegramBackBtnHandler)
  }, [])

  useEffect(() => {
    if (isLoading) {
      telegramUseCases.setMainBtnText('Loading')
      telegramUseCases.mainBtnDisable()
    }
  }, [isLoading])

  useEffect(() => {
    if (cart.items.length > 0) {
      telegramUseCases.mainBtnShow()
      if (location.pathname === '/orders') {
        telegramUseCases.setMainBtnText(`Pay ${cart.total} ֏`)
      } else if (location.pathname === '/checkout') {
        telegramUseCases.setMainBtnText(`Proceed to Checkout`)
      } else {
        telegramUseCases.setMainBtnText(`View Cart`)
      }
    } else {
      telegramUseCases.mainBtnHide()
    }
  }, [cart, location.pathname])

  useEffect(() => {
    switch (location.pathname) {
      case '/collections':
        telegramUseCases.backBtnHide()
        break

      default:
        telegramUseCases.backBtnShow()
        break
    }
  }, [location.pathname])
}