import axios from 'axios'

export class PaymentService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_PRODUCTS_API_URL
    })
  }

  async payment(data) {
    const response = await this.api.post('/payment', {
      data
    })
    return response.data
  }

  async orderStatus(orderId) {
    console.log('PaymentService@orderStatus@orderId', orderId)
    const response = await this.api.get(`/order-status/${orderId}`)
    return response.data
  }

  async sendOrderInvoice(data) {
    const response = await this.api.post('/send-order-invoice', data)
    return response.data
  }
}