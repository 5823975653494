import telegramUseCase from './useCases/telegramUseCase'
import TelegramService from './services/TelegramService'
import { ProductUseCase } from './useCases/ProductUseCase'
import { ProductService } from './services/ProductService'
import { PaymentUseCase } from './useCases/PaymentUseCase'
import { PaymentService } from './services/PaymentService'

const telegramUseCases = telegramUseCase(TelegramService())
const paymentUseCase = new PaymentUseCase(new PaymentService())
const productUseCase = new ProductUseCase(new ProductService())

export { paymentUseCase, telegramUseCases, productUseCase }
