import React, { useReducer } from 'react'

const StoreContext = React.createContext(null)

export const DELIVERY_PRICE = 1000

export const StoreProvider = ({ children }) => {
  const [cart, setCart] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'addToCart':
          const existingProduct = state.items.find(
            (item) => item.id === action.productId && item.productOption === action.productOption.name
          )
          console.log('is existing product : ', existingProduct)
          if (existingProduct) {
            return {
              items: state.items.map(
                increaseItemCountBy({
                  productId: action.productId,
                  productOption: action.productOption.name,
                  quantity: action.quantity
                })
              ),
              total:
                state.total + (action.quantity *
                  Math.floor(action.productOption.price || action.product.price))
            }
          }

          return {
            items: [
              ...state.items,
              createNewOrder({
                product: action.product,
                productOption: action.productOption.name,
                quantity: action.quantity,
                categories: action.categories
              })
            ],
            total:
              state.total + (action.quantity *
                Math.floor(action.productOption.price || action.product.price))
          }
        case 'deleteItem':
          console.log('delete item : ', action)
          return {
            items: state.items.filter(
              (item) => !(item.id === action.product.id && item.productOption === action.product.productOption)
            ),
            total: state.total - action.product.price * action.product.quantity
          }

        case 'updateQuantity': {
          const product = state.items.find(
            (item) => item.id === action.product.id && item.productOption === action.product.productOption
          )

          return {
            items: state.items.map(product => {
              if (product.id === action.product.id && product.productOption === action.product.productOption) {
                return {
                  ...product,
                  quantity: action.quantity
                }
              }
              return product
            }),
            total: product.quantity < action.quantity
              ? state.total + (action.quantity - product.quantity) * product.price
              : state.total - (product.quantity - action.quantity) * product.price
          }
        }
        case 'setCart': {
          return {
            items: Array.isArray(action.cart.items) ? action.cart.items : [],
            total: parseFloat(action.cart.total || 0)
          }
        }
        case 'resetCart': {
          return {
            items: [],
            total: DELIVERY_PRICE
          }
        }
        default:
          return
      }
    },
    {
      total: DELIVERY_PRICE,
      items: []
    }
  )


  return (
    <StoreContext.Provider
      value={{
        cart: cart,
        setCart: setCart
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContext

const increaseItemCountBy = ({
                               productId,
                               productOption,
                               quantity = 1
                             }) => (item) => {
  return item.id === productId && item.productOption === productOption
    ? {
      ...item,
      quantity: item.quantity + quantity
    }
    : item
}

const createNewOrder = ({
                          product,
                          productOption,
                          quantity = 1
                        }) => ({
  id: product.id,
  image: product.imageSrc,
  title: product.name,
  price: Math.floor(product.price),
  productOption: productOption,
  quantity: quantity,
  categories: product.categories
})
