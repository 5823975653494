import { Flex, VStack } from '@chakra-ui/react'
import React from 'react'
import { OrderCard } from './OrderCard'

export const Orders = ({ cart, setCart, onOpenDetailedProduct }) => {
  return (
    <VStack
      width="full"
      spacing="5"
      mt="3"
      px="4"
      pt="2"
    >
      {cart.items.map((product, index) => (
        <OrderCard
          onDeleteOrder={() =>
            setCart({
              type: 'deleteItem',
              product
            })
          }
          key={product.id + product.productOption}
          product={product}
          index={index}
          onOpenDetailedProduct={onOpenDetailedProduct}
          onQuantityChange={(quantity) =>
            setCart({
              type: 'updateQuantity',
              product,
              quantity
            })
          }
        />
      ))}
    </VStack>
  )
}
